import React from "react";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ManufacturersHero from "../components/manufacturers-hero";
import ImageSide from "../components/image-side";
import Container from "react-bootstrap/Container";
import MotForm from "../components/mot-form";
import Badges from "../components/badges";
import OurServicesSection from "../components/our-services-section";
import Reviews from "../components/reviews";
import ImageSideContainer from "../components/image-side-container";
import RepairGuaranteeSection from "../components/repair-gurantee-section";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import { Button } from "react-bootstrap";

const ManufacturerTemplate = ({ data }) => {
	const { wpManufacturer } = data;

	const { siteUrl } = data.site.siteMetadata;
	const manufacturerData = wpManufacturer;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `${manufacturerData.title}`,
				item: {
					url: `${siteUrl}/manufacturers/${manufacturerData.slug}`,
					id: `${siteUrl}/manufacturers/${manufacturerData.slug}`,
				},
			},
		],
	};

	const { seoFields, manufacturerFields } = manufacturerData;

	const {
		manufacturerBanner,
		manufacturerLeftContentRightImage,
		manufacturerContentModule,
		manufacturerReviews,
		manufacturerRightContentLeftImage,
		manufacturerGetInTouchCta,
		manufacturerLogoSlider,
		manufacturerContactForm,
		manufacturerServicesCards,
		manufacturerServiceSchema,
		manufacturerGuaranteeSection,
	} = manufacturerFields;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.ecfcarcare.co.uk/manufacturers/${manufacturerData.slug}`,
		image: `https://www.ecfcarcare.co.uk${seoFields.image?.node.localFile.publicURL}`,
		mpn: `https://www.ecfcarcare.co.uk/manufacturers/${manufacturerData.slug}`,
		sku: `https://www.ecfcarcare.co.uk/manufacturers/${manufacturerData.slug}`,
		description: `${manufacturerServiceSchema?.manufacturerServiceSchemaDescription}`,
		logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
		name: "ECF Car Care",
		url: "https://www.ecfcarcare.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
			image: `https://www.ecfcarcare.co.uk${seoFields.image?.node.localFile.publicURL}`,
			name: "ECF Car Care",
			url: "https://www.ecfcarcare.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: "https://www.ecfcarcare.co.uk/reviews",
			datePublished: `${data.reviews.reviewDate}`,
			reviewBody: `${data.reviews.reviewContent}`,
			author: {
				"@type": "Person",
				name: `${data.reviews.reviewAuthor}`,
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "ECF Car Care",
				sameAs: "https://www.ecfcarcare.co.uk",
			},
		},
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title={`${seoFields.title}`}
				description={`${seoFields.metaDescription}`}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/manufacturers/${manufacturerData.slug}`,
					title: `${seoFields.title}`,
					description: `${seoFields.metaDescription}`,
					images: [
						{
							url: `${seoFields.image?.node.localFile.publicURL}`,
							width: `${seoFields.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields.image?.node.altText}`,
						},
					],
				}}
			/>

			{manufacturerBanner && (
				<section>
					<ManufacturersHero
						title={manufacturerBanner.manufacturerBannerHeading}
						backgroundImage={
							manufacturerBanner.manufacturerBannerBackgroundImage?.node
								?.localFile.childImageSharp.gatsbyImageData
						}
						cta={manufacturerBanner.manufacturerBookAppointmentLink}
					/>
				</section>
			)}
			{manufacturerLeftContentRightImage &&
				!checkPropertiesForNull(manufacturerLeftContentRightImage, [
					"manufacturerLeftContentHeading",
					"manufacturerLeftContentText",
				]) && (
					<div className="py-lg-8 py-5">
						<ImageSideContainer
							mPage
							image={
								manufacturerLeftContentRightImage.manufacturerRightImage.node
									?.localFile.childImageSharp.gatsbyImageData
							}
							imageAlt={
								manufacturerLeftContentRightImage.manufacturerRightImage.node
									?.altText
							}
							title={
								manufacturerLeftContentRightImage.manufacturerLeftContentHeading
							}
							order="last"
							imagePadding="pb-5 pb-lg-0"
							imageStyle="h-auto"
							text={
								manufacturerLeftContentRightImage.manufacturerLeftContentText
							}
							ctaButton={manufacturerLeftContentRightImage.manufacturerCta}
						/>
					</div>
				)}
			{manufacturerContentModule &&
				!checkPropertiesForNull(manufacturerContentModule, [
					"manufacturerContentHeading",
				]) && (
					<section>
						<Container>
							<Row>
								<Col>
									<h3 className="inter-bold">
										{manufacturerContentModule.manufacturerContentHeading}
									</h3>
									{manufacturerContentModule?.manufacturerContentText && (
										<SafeHtmlParser
											htmlContent={
												manufacturerContentModule?.manufacturerContentText
											}
										/>
									)}
								</Col>
							</Row>
						</Container>
					</section>
				)}

			<section>
				<Reviews title={manufacturerReviews?.manufacturerReviewsHeading} />
			</section>
			{manufacturerRightContentLeftImage &&
				!checkPropertiesForNull(manufacturerRightContentLeftImage, [
					"manufacturerRightContentHeading",
					"manufacturerRightContentText",
				]) && (
					<div className="pt-5 pt-lg-8 body-left-image-content">
						<ImageSide
							image={
								manufacturerRightContentLeftImage?.manufacturerLeftImage.node
									?.localFile.childImageSharp.gatsbyImageData
							}
							imageAlt={
								manufacturerRightContentLeftImage?.manufacturerLeftImage.node
									?.altText
							}
							title={
								manufacturerRightContentLeftImage?.manufacturerRightContentHeading
							}
							order="start"
							imagePadding="px-lg-5  pb-5 pb-lg-0 px-3 "
							text={
								manufacturerRightContentLeftImage?.manufacturerRightContentText
							}
							ctaButton={
								manufacturerRightContentLeftImage?.manufacturerProfileButton
							}
							centerButton={true}
						/>
					</div>
				)}
			{manufacturerGetInTouchCta &&
				!checkPropertiesForNull(manufacturerGetInTouchCta, [
					"manufacturerGetInTouchHeading",
					"manufacturerGetInTouchContent",
				]) && (
					<section className="pt-lg-8 pt-5">
						<Container>
							<Row>
								<Col className={`mb-5 text-center`}>
									<h2>
										{manufacturerGetInTouchCta.manufacturerGetInTouchHeading}
									</h2>
								</Col>
							</Row>
							<Row>
								<Col>
									<SafeHtmlParser
										htmlContent={
											manufacturerGetInTouchCta?.manufacturerGetInTouchContent
										}
									/>
								</Col>
							</Row>
							{manufacturerGetInTouchCta?.manufacturerGetInTouchButton &&
								manufacturerGetInTouchCta?.manufacturerGetInTouchButton.url && (
									<div className="text-center">
										<Button
											style={{ borderRadius: "0px" }}
											variant="secondary"
											as={Link}
											to={
												manufacturerGetInTouchCta.manufacturerGetInTouchButton
													.url
											}
											className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
										>
											{
												manufacturerGetInTouchCta?.manufacturerGetInTouchButton
													?.title
											}
										</Button>
									</div>
								)}
						</Container>
					</section>
				)}
			{manufacturerGuaranteeSection &&
				!checkPropertiesForNull(manufacturerGuaranteeSection, [
					"manufacturerGuaranteeSectionHeading",
					"manufacturerGuaranteeSectionContent",
				]) && (
					<RepairGuaranteeSection
						title={
							manufacturerGuaranteeSection?.manufacturerGuaranteeSectionHeading
						}
						content={
							manufacturerGuaranteeSection?.manufacturerGuaranteeSectionContent
						}
					/>
				)}

			{manufacturerLogoSlider &&
				!checkPropertiesForNull(manufacturerLogoSlider, [
					"manufacturerLogoSliderHeading",
					"manufacturerLogos",
				]) && (
					<Badges
						title={manufacturerLogoSlider.manufacturerLogoSliderHeading}
						text={manufacturerLogoSlider.manufacturerLogoSliderBottomNotice}
						logos={manufacturerLogoSlider.manufacturerLogos?.nodes}
					/>
				)}
			<section id="book" className="pb-5 pb-lg-0 pt-5">
				<Container>
					<Row className="justify-content-center">
						<Col lg={6}>
							<h2 className="text-center pb-4">
								{manufacturerContactForm.manufacturerContactFormHeading ||
									"BOOK YOUR APPOINTMENT"}
							</h2>
							<div
								style={{ background: "#F9F9F9", borderRadius: "18px" }}
								className="p-md-5 p-4"
							>
								<MotForm page="Mercedes Approved Accident Repair" />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			{manufacturerServicesCards &&
				!checkPropertiesForNull(manufacturerServicesCards, [
					"manufacturerServicesCardsHeading",
					"manufacturerServicesCards",
				]) && (
					<OurServicesSection
						title={manufacturerServicesCards.manufacturerServicesCardsHeading}
						services={
							manufacturerServicesCards.manufacturerServicesCards?.nodes
						}
					/>
				)}
		</Layout>
	);
};

export default ManufacturerTemplate;

export const manufacturerData = graphql`
	query ($id: String!) {
		wpManufacturer(id: { eq: $id }) {
			title
			slug
			manufacturerFields {
				manufacturerBanner {
					manufacturerBannerHeading
					manufacturerBookAppointmentLink {
						title
						target
						url
					}
					manufacturerBannerBackgroundImage {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				manufacturerLeftContentRightImage {
					manufacturerLeftContentHeading
					manufacturerLeftContentText
					manufacturerCta {
						title
						url
						target
					}
					manufacturerRightImage {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				manufacturerContentModule {
					manufacturerContentHeading
					manufacturerContentText
				}
				manufacturerReviews {
					manufacturerReviewsHeading
				}
				manufacturerRightContentLeftImage {
					manufacturerRightContentHeading
					manufacturerRightContentText
					manufacturerProfileButton {
						title
						target
						url
					}
					manufacturerLeftImage {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				manufacturerGetInTouchCta {
					manufacturerGetInTouchHeading
					manufacturerGetInTouchContent
					manufacturerGetInTouchButton {
						title
						target
						url
					}
				}
				manufacturerGuaranteeSection {
					manufacturerGuaranteeSectionContent
					manufacturerGuaranteeSectionHeading
				}
				manufacturerLogoSlider {
					manufacturerLogoSliderHeading
					manufacturerLogoSliderBottomNotice
					manufacturerLogos {
						nodes {
							... on WpVehicleLogo {
								vehicleLogoFields {
									logoUrl
									logoImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
				}
				manufacturerContactForm {
					manufacturerContactFormHeading
				}
				manufacturerServicesCards {
					manufacturerServicesCardsHeading
					manufacturerServicesCards {
						nodes {
							... on WpService {
								title
								servicesPostFields {
									serviceUrl {
										target
										title
										url
									}
								}
								featuredImage {
									node {
										altText
										localFile {
											publicURL
											childImageSharp {
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
					}
				}
				manufacturerServiceSchema {
					manufacturerServiceSchemaDescription
				}
			}
			seoFields {
				title
				opengraphTitle
				opengraphDescription
				metaDescription
				localBusinessSchema
				image {
					node {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
							publicURL
						}
					}
				}
			}
		}
		schemaLogo: file(relativePath: { eq: "icon.png" }) {
			publicURL
		}
		reviews {
			reviewCount
			reviewAuthor
			reviewDate
			reviewContent
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
